export const BANNER_TYPES = {
  MAIN_SLIDER_BANNER_ID: 1,
  MAIN_SLIDER_MULTI_BANNER_ID: 8,
  TEK_TIP_PAKET: 'Tek Tip Paket',
  PARTNER_BANNER: 'Üst Slider',
  PARTNER_BANNER_ID: 5,
  FUN_SLIDER_BANNER_ID: 6,
  TOBACCO_ACADEMY_BANNER_ID: 7,
  ENTERPRICE_ACADEMY_BANNER_ID: 9,
};

export const BANNER_MODES = {
  CLASSIC_BANNER: 1,
  DYNAMIC_BANNER: 2,
};

export default {
  BANNER_TYPES,
  BANNER_MODES,
};
